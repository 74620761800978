import React from 'react'

const MainLayout = ({children}) => {
    return (
        <>
            { children }
        </>
    )
}

export default MainLayout;
