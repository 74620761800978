import { isEmpty, get, has } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {recognizeText} from "api/lexbot";

export const RECOGNIZE_TEXT = 'lexbot/RECOGNIZE_TEXT'
export const IS_SENDING = 'lexbot/IS_SENDING'
export const CURRENT_INTENT = 'lexbot/CURRENT_INTENT'
export const CURRENT_INTENT_ID = 'lexbot/CURRENT_INTENT_ID'
export const CURRENT_QUESTION = 'lexbot/CURRENT_QUESTION'
export const BOT_CONVERSATIONS = 'lexbot/BOT_CONVERSATIONS'
export const INTENT_PARAMETERS = 'lexbot/INTENT_PARAMETERS'
export const PREV_SESSIONID = 'PREV_SESSIONID';
export const sessionId = uuidv4()
const initialState = {
    isSending: false,
    listUsers: null,
    detailUser: null,
    sessionId: uuidv4(),
    currentIntentName: '',
    currentIntentId: '',
    currentQuestion: '',
    botConversations: [],
    intentParameters: [],
    conversationList:[
        {
            messageType : 'bot',
            message: 'Pre-qualify today for the best possible SBA 7(a) loan terms. \n \n Never a fee and no credit check. \n',
            contentType: 'PlainText'
        }
    ]
};

export const currentIntentReducer = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: CURRENT_INTENT,
                payload: data
            }
        )
    }
}

export const currentIntentIdReducer = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: CURRENT_INTENT_ID,
                payload: data
            }
        )
    }
}

export const currentQuestion = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: CURRENT_QUESTION,
                payload: data
            }
        )
    }
}

export const botConversation = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: BOT_CONVERSATIONS,
                payload: data
            }
        )
    }
}

export const intentParamaters = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: INTENT_PARAMETERS,
                payload: data
            }
        )
    }
}

export const sendingStartReducer = () => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: IS_SENDING,
                payload: true
            }
        )
    }
}

export const sendingCompletedReducer = () => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: IS_SENDING,
                payload: false
            }
        )
    }
}

export const addUserQueryReducer = (data, messageType='human') => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: RECOGNIZE_TEXT,
                payload: {
                    messageType: messageType,
                    message: data['query'],
                    contentType: 'PlainText',
                    imageResponseCards: null
                }
            }
        )
    }
}

export const prevSessionIdReducer = (data) => {
    return (dispatch, getState) => {
        dispatch(
            {
                type: PREV_SESSIONID,
                payload: data
            }
        )
    }
}

const getNextQuestion = data => {
    if( has(data, 'fulfilmentText') && !isEmpty(data.fulfilmentText)) {
        return data.fulfilmentText;
    }
    return '';
}

const cleanOldImageCards = data => data.map(p =>
    p.imageResponseCards != null
      ? { ...p, imageResponseCards: null }
      : p
);

const getImageResponseCard = data => {
    if( has(data, 'cardOptions') && !isEmpty(data.cardOptions)) {
        if(data.cardOptions.length > 1 && data.hasOptions){
            return data.cardOptions;
        }
    }
    return null;
}

export const recognizeTextReducer = (data) => {
    const _sessionId = data?.sessionId ? data.sessionId : sessionId;
    return (dispatch, getState) => {
        dispatch(sendingStartReducer());
        dispatch(botConversation(
            {
                intentId: !!data.currentIntentId ? data.currentIntentId : '',
                intentName: data.currentIntentName,
                question: data.currentQuestion,
                answer: data.query,
                sessionId: _sessionId
            }
        ));
        recognizeText({...data, sessionId: _sessionId}).then((response) => {
            dispatch(
            {
                type: RECOGNIZE_TEXT,
                payload: {
                    messageType: 'bot',
                    message: getNextQuestion(response.data),
                    intentId: !!response.data.intentId ? response.data.intentId : '',
                    intentName: !!response.data.intentName ? response.data.intentName : '',
                    imageResponseCards: getImageResponseCard(response.data)
                }
            });
            dispatch(sendingCompletedReducer());
            dispatch(currentQuestion(getNextQuestion(response.data)));
            dispatch(currentIntentReducer(response.data.intentName));
            dispatch(currentIntentIdReducer(response.data.intentId));
            dispatch(intentParamaters(JSON.parse(response.data.parameter)));
        });
    }
}

const lexBotReducers = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case IS_SENDING:
            return {
                ...state,
                isSending: payload
            }
        case RECOGNIZE_TEXT :
            return {
                ...state,
                conversationList: [...cleanOldImageCards(state.conversationList), payload]
            }
        case CURRENT_INTENT :
            return {
                ...state,
                currentIntentName: payload
            }
        case CURRENT_INTENT_ID :
            return {
                ...state,
                currentIntentId: payload
            }
        case CURRENT_QUESTION :
            return {
                ...state,
                currentQuestion: payload
            }
        case BOT_CONVERSATIONS :
            return {
                ...state,
                botConversations: [ ...state.botConversations, payload ]
            }
        case INTENT_PARAMETERS :
            return {
                ...state,
                intentParameters: [ ...state.intentParameters, payload ]
            }
        case PREV_SESSIONID:
            return {
                ...state,
                prevSessionId: payload
            };
        default:
            return {...state}
    }
};

export default lexBotReducers;
