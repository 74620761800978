import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, unset } from 'lodash';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {useDispatch} from "react-redux";
import {recognizeTextReducer, addUserQueryReducer} from "reducers/lexBotReducers";
import BotIcon from './assets/chatbot.png';
import slugify from 'slugify';

const useStyles = makeStyles({
    botMessage:{
        background: '#f0f0f0',
        display: 'table',
        color: '#333',
        padding: '10px 20px',
        borderRadius: '12px 12px 12px 0',
        width: 'auto',

        '& span':{
            whiteSpace: 'pre-line'
        }
    },
    botIcon:{
        float: 'left',
        minWidth: 48,
        marginTop: 5,
        marginRight: 10
    },
    cardButton:{
        marginRight: 5,
        marginBottom: 5,
        fontSize: 13,
        borderRadius: 20,
        textTransform: 'none',

        '&:hover':{
            background: 'rgba(63, 81, 181, 0.09) !important'
        },
        '&:focus':{
            outline: 'none'
        }
    },
    cardContainHide:{
        display: 'none'
    },
    avatarImage:{
        padding: 5,
        border: '1px solid #ccc',
        width: 35,
        height: 35
    },
    formGroupRoot:{
        display: 'inline-block'
    }
  });

const BotMessage = ({ conversation, updateMultioptionToQuery, currentIntentName, currentIntentId, currentQuestion, botConversations, intentParameters, prevSessionId, setIsCard }) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { imageResponseCards = [], message, intentName } = conversation;
    const [stateObject, setStateObject] = useState({});

    let optionArray = [];
    const callbackOptionsquery = async () => {
        await updateMultioptionToQuery(stateObject);
    }

    isEmpty(imageResponseCards) ? setIsCard(false) : setIsCard(true);

    const handleChange = async (event) => {
        if(!!event.target.checked) {
            optionArray.push(event.target.value);
        }
        else if(!event.target.checked) {
            for( var i = 0; i < optionArray.length; i++){
                if ( optionArray[i] === event.target.value) {
                    optionArray.splice(i, 1);
                    i--;
                }
            }
        }

        var optionsObj = stateObject;
        if(!!event.target.checked) {
            optionsObj = Object.assign(optionsObj, { [event.target.name]: event.target.value });
            await setStateObject({ ...stateObject, ...optionsObj });
        } else if (!event.target.checked){
            optionsObj = Object.assign(optionsObj, stateObject);
            unset(optionsObj, [event.target.name]);
            await setStateObject(optionsObj);
        }
        await callbackOptionsquery();
    };

    const handleOnClickCard = (cardValue) => {
        const requestData = {
            'query': cardValue,
            'currentIntentName': currentIntentName,
            'currentIntentId': currentIntentId,
            'currentQuestion': currentQuestion,
            'lastConversation': botConversations,
            'intentParameters': intentParameters,
            'sessionId': prevSessionId
        };
        dispatch(addUserQueryReducer(requestData));
        dispatch(recognizeTextReducer(requestData));
    }

    return (
        <div>
            {!!message && <ListItem key="1">
                <Grid container>
                    <Grid item xs={12}>
                        <ListItemIcon className={classes.botIcon}>
                            <Avatar alt="Yoursba ChatBot" className={classes.avatarImage} src={BotIcon}/>
                        </ListItemIcon>
                        <ListItemText className={classes.botMessage} align="left" primary={typeof message === 'string' ? <div dangerouslySetInnerHTML={{__html: message}}></div> : message}></ListItemText>
                    </Grid>
                </Grid>
            </ListItem>
            }
            { !isEmpty(imageResponseCards) && (<ListItem key="8">
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup classes={{ root: classes.formGroupRoot}}>
                        {imageResponseCards.map(imageResponseCard => <>
                            {intentName == "Email_Yes_liquidity_yes_business_price" ? <FormControlLabel
                                control={<Checkbox name={slugify(imageResponseCard.text, '_').toLowerCase()} value={imageResponseCard.text} onChange={handleChange} />}
                                label={imageResponseCard.text}
                            /> :
                        <Button variant="outlined" onClick={e => handleOnClickCard(imageResponseCard.value)} className={classes.cardButton} color="primary">
                            {imageResponseCard.text}
                        </Button>}
                        </>)}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </ListItem>)}
        </div>
    )
}

export default BotMessage
