import Home from "pages/home";
import MainLayout from "layouts/main";

const router = [
    {
        path: '/',
        component: Home,
        exact : true,
        layout : MainLayout,
    }
]

export default router;
