import React,{ useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {useDispatch, useSelector} from "react-redux";
import {recognizeTextReducer, addUserQueryReducer} from "reducers/lexBotReducers";
import TypingLoader from './assets/typing.svg';
import BotIcon from './assets/chatbot.png';
import BotMessage from './BotMessage';
import {map} from "lodash";
import { getBotConversationByEmail } from 'api/lexbot';
import {botConversation, intentParamaters, prevSessionIdReducer} from "../../reducers/lexBotReducers";

const useStyles = makeStyles({
    chatSection: {
      width: '100%',
      display: 'contents'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
      height: 'calc(100vh - 105px)',
      overflowY: 'auto'
    },
    customerMessage:{
        background: '#074e9d',
        width: 'auto',
        display: 'inline-block',
        float: 'right',
        color: '#fff',
        padding: '12px 20px',
        borderRadius: '12px 12px 0 12px'
    },
    botMessage:{
        background: '#f0f0f0',
        width: 'auto',
        display: 'inline-block',
        float: 'left',
        color: '#333',
        padding: '10px 20px',
        borderRadius: '12px 12px 12px 0'
    },
    botIcon:{
        float: 'left',
        minWidth: 48,
        marginTop: 0,
        marginRight: 10
    },
    sendButton:{
        background: '#074e9d',

        '&:hover':{
            background: '#074e9d'
        },
        '&:focus':{
            outline: 'none'
        }
    },
    avatarImage:{
        padding: 5,
        border: '1px solid #ccc',
        width: 35,
        height: 35
    },
    inputContainer:{
        borderTop: '1px solid #c8c8c8',
        padding: '15px 40px 15px 15px',
        position: '-webkit-sticky',
        position: 'sticky',
        bottom: 0,
        zIndex: 9,
        background: '#fff'
    }
  });

const Chatbot = props => {
    const messageEl = useRef(null);
    const [humanQuery, setHumanQuery] = useState('');
    const [isCard, setIsCard] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const { conversationList, currentIntentName, currentIntentId, currentQuestion, botConversations, intentParameters, isSending, prevSessionId } = useSelector(store => store.lexbot);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('userId');
    const currencyIntents = ['retirement_account', 'cash', 'stocks_bonds', 'gift', 'home_equity', 'outside_investor', 'DownPaymentSource_sellerfinancing_yes', 'ProjectInformation - yes', 'Email_Yes', 'Email_Yes_liquidity - yes'];

    useEffect( async () => {
        /* Get last conversation list from user by given email id and also display conversation list into the oliver window */
        if(userId) {
             getBotConversationByEmail({email: userId}).then(response => {
                if(response && Array.isArray(response)){
                    const personConversion = response.filter((conversion)=>conversion.parameter?.person?.name !== undefined && conversion.parameter?.person?.name.indexOf(' ') > 0);
                    const lastConversation = response && response.length > 0 ? response[response.length - 1] : '';
                    //override the sessionId from previous conversations
                   const conversationSessionId = lastConversation && lastConversation.session_id;
                   let _botConversations = [];
                   let _intentParameters = [];
                     response.map(row => {
                        if(row.question && row.answer && row.intent_name !== 'Default Fallback Intent'){
                             dispatch(addUserQueryReducer({'query': row.question}, 'bot'));
                             dispatch(addUserQueryReducer({'query': row.answer}, 'human'));
                            const botConversion = {
                                intentId: !!row.intent_id ? row.intent_id : '',
                                intentName: row.intent_name,
                                question: row.question,
                                answer: row.answer,
                                sessionId: conversationSessionId
                            };
                             dispatch(botConversation(botConversion));
                            _botConversations.push(botConversion);
                            _intentParameters.push(row.parameter);
                            dispatch(intentParamaters(row.parameter));
                        }
                    });

                    if(response && response.length > 0){
                        dispatch(prevSessionIdReducer(lastConversation.session_id));
                        if(lastConversation.intent_name === 'phone_number') {
                            dispatch(addUserQueryReducer({'query': 'You did great! That\'s all we need for now!'}, 'bot'));
                        } else {
                            const data = {
                                'currentQuestion': lastConversation.question,
                                'query': lastConversation.answer,
                                'currentIntentId': lastConversation.intent_id,
                                'currentIntentName': lastConversation.intent_name,
                                'lastConversation': _botConversations,
                                'intentParameters': _intentParameters,
                                'sessionId': lastConversation.session_id
                            };
                            dispatch(prevSessionIdReducer(lastConversation.session_id));
                            dispatch(recognizeTextReducer(data));
                        }
                    } else {
                        dispatch(recognizeTextReducer({
                            'query': 'Pre-qualify today with no credit check and never a fee. What will the loan be used for?'
                        }));
                    }
                } else {
                    dispatch(recognizeTextReducer({
                        'query': 'Pre-qualify today with no credit check and never a fee. What will the loan be used for?'
                    }));
                }
            });
        } else {
            dispatch(recognizeTextReducer({
                'query': 'Pre-qualify today with no credit check and never a fee. What will the loan be used for?'
            }));
        }
    }, []);

    const inputRef = useRef();

    const onInputChange = (e) => {
        if(currentIntentName === 'phone_number'){
            let query = formatPhoneNumber(e.target.value);
            setHumanQuery(query);
            return;
        }
        if(currencyIntents.includes(currentIntentName)){
            let query = currencyFormat(e.target.value);
            setHumanQuery(query);
            return;
        }
        setHumanQuery(e.target.value);
    };

    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early

        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const updateMultioptionToQuery = (options) => {
        const optionsValues = Object.values(options);
        const translated = map(optionsValues).join(', ');
        setHumanQuery(translated);
    };


    const currencyFormat = (amount) => {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!amount) return amount;

        // clean the input for any non-digit values.
        const numberAmount = amount.replace(/[^\d]/g, "");

        let dollarUS = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return dollarUS.format(numberAmount);
    };


    const handleOnSend = () => {
        if (humanQuery !== '') {
            const requestData = {
                'query': humanQuery,
                'currentIntentName': currentIntentName,
                'currentIntentId': currentIntentId,
                'currentQuestion': currentQuestion,
                'lastConversation': botConversations,
                'intentParameters': intentParameters,
                'sessionId': prevSessionId
            };

            dispatch(addUserQueryReducer(requestData));
            dispatch(recognizeTextReducer(requestData));
            setHumanQuery('');
        }
    };

    const onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            handleOnSend();
        }
    };

    useEffect(() => {
        if (messageEl) {
          messageEl.current.addEventListener('DOMNodeInserted', event => {
            const { currentTarget: target } = event;
            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            const timeout = setTimeout(() => {
                inputRef.current.focus();
              }, 100);
          });
        }
      }, []);

  return (
      <div>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={12}>
                    <List className={classes.messageArea} ref={messageEl}>
                        { conversationList.map(conversation => conversation.messageType === 'bot' ? <BotMessage conversation={conversation} {...props} updateMultioptionToQuery={updateMultioptionToQuery} currentIntentName={currentIntentName} currentIntentId={currentIntentId} currentQuestion={currentQuestion} botConversations={botConversations} intentParameters={intentParameters} prevSessionId={prevSessionId} setIsCard={setIsCard} /> : <ListItem>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText className={classes.customerMessage} align="right" primary={conversation.message}></ListItemText>
                                </Grid>
                            </Grid>
                        </ListItem>)}
                        {isSending ? <ListItem key="9">
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemIcon className={classes.botIcon}>
                                        <Avatar alt="Yoursba ChatBot" className={classes.avatarImage} src={BotIcon} />
                                    </ListItemIcon>
                                    <Typography
                                        component="span"
                                        variant="body1"
                                        color="textPrimary"
                                        className={classes.botMessage}
                                    >
                                        <img src={TypingLoader} alt="Typing Icon" />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem> : '' }
                    </List>
                    <Grid container classes={{ root: classes.inputContainer }}  >
                        <Grid item xs={11}>
                            <TextField id="outlined-basic-email" label="Type your message here..." inputRef={inputRef} autoFocus disabled={isSending || isCard} onKeyDown={onKeyDownHandler} value={humanQuery} onChange={e => onInputChange(e)} fullWidth autocomplete="off"/>
                        </Grid>
                        <Grid xs={1} align="right">
                            <Fab color="primary" disabled={isSending} aria-label="add" classes={{ root: classes.sendButton}} onClick={handleOnSend} ><SendIcon /></Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
      </div>
  )
}

export default Chatbot
