import React from 'react'
import ChatBot from '../../components/chatbot'

const Home = () => {
    return (
        <>
            <ChatBot />
        </>
    )
}

export default Home
